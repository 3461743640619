AOS.init();
function initSmoothScroll() {
  const navLinks = document.querySelectorAll('.nav-link');
  const header = document.querySelector('header');
  const headerHeight = header?.offsetHeight || 0;

  window.addEventListener('load', function() {
    const scrollTarget = localStorage.getItem('scrollTarget');
    if (scrollTarget) {
      window.scrollTo(0, 0);

      setTimeout(() => {
        const targetSection = document.querySelector('#' + scrollTarget);
        if (targetSection) {
          const targetPosition = targetSection.offsetTop - headerHeight;
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          });
          localStorage.removeItem('scrollTarget');
        }
      }, 500);
    }
  });

  navLinks.forEach(link => {
    link.addEventListener('click', function(e) {
      const targetSection = this.getAttribute('data-section');

      if (targetSection) {
        if (window.location.pathname.endsWith('index.html') || window.location.pathname.endsWith('/')) {
          e.preventDefault();

          const targetElement = document.querySelector('#' + targetSection);
          if (targetElement) {
            const targetPosition = targetElement.offsetTop - headerHeight;

            window.scrollTo({
              top: targetPosition,
              behavior: 'smooth'
            });

            navLinks.forEach(link => link.classList.remove('active'));
            this.classList.add('active');
          }
        } else {
          localStorage.setItem('scrollTarget', targetSection);
        }
      }
    });
  });

  if (window.location.pathname.endsWith('index.html') || window.location.pathname.endsWith('/')) {
    let ticking = false;
    window.addEventListener('scroll', function() {
      if (!ticking) {
        window.requestAnimationFrame(function() {
          const scrollPosition = window.scrollY + headerHeight + 100;

          const sections = document.querySelectorAll('section[id]');
          sections.forEach(section => {
            const sectionTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;
            const sectionId = section.getAttribute('id');

            if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
              navLinks.forEach(link => {
                const targetSection = link.getAttribute('data-section');
                link.classList.toggle('active', targetSection === sectionId);
              });
            }
          });

          ticking = false;
        });

        ticking = true;
      }
    });
  }
}
document.addEventListener('DOMContentLoaded', initSmoothScroll);

function initHamburger() {
  const hamburger = document.querySelector('.hamburger');
  const header = document.querySelector('.top');
  const menuLinks = document.querySelectorAll('.nav-list a');
  const body = document.body;
  if (!hamburger) return;
  hamburger.addEventListener('click', function() {
    this.classList.toggle('is-active');
    header.classList.toggle('menu-open');
    body.classList.toggle('no-scroll');
  });

  function closeMenu() {
    hamburger.classList.remove('is-active');
    header.classList.remove('menu-open');
    body.classList.remove('no-scroll');
  }

    menuLinks.forEach(link => {
    link.addEventListener('click', () => {
      closeMenu();
    });
  });
  menuLinks.forEach(link => {
    link.addEventListener('click', () => {
      closeMenu();
    });
  });

  document.addEventListener('click', (e) => {
    if (header.classList.contains('menu-open') && 
        !header.contains(e.target) && 
        !hamburger.contains(e.target)) {
      closeMenu();
    }
  });
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape' && header.classList.contains('menu-open')) {
      closeMenu();
    }
  });
}

document.addEventListener('DOMContentLoaded', function() {
  initHamburger();
});

Fancybox.bind('[data-fancybox]', {});
